<template>
    <div class="chart-holder">
        <div class="l-padded datepicker">
            <DateRangeInput v-model="customRange" @input="loadHistoryCustom" />
        </div>

        <div class="chart">
            <input
                id="display-annotation-labels"
                v-model="displayAnnotationLabelsChecked"
                type="checkbox"
            />

            <label for="display-annotation-labels">
                {{ $t('displayAnnotationLabels') }}
            </label>

            <div v-if="isLoading" class="l-stack l-center l-padded">
                <Spinner size="medium" line-fg-color="#000" :speed="1" />
            </div>

            <ApexCharts
                v-if="!isLoading"
                :class="{
                    'chart-display-annotation-labels': displayAnnotationLabelsChecked,
                }"
                height="420px"
                :options="chartOptions"
                :series="series"
            />
        </div>
    </div>
</template>

<script>
import moment from 'moment-timezone'
import Spinner from 'vue-simple-spinner'
import ApexCharts from 'vue-apexcharts'

import { httpHelper, measurementHelper } from '@/utils'

import ChartHelper from '@/mixins/ChartHelper'
import DateRangeInput from '@/components/DateRangeInput'

export default {
    name: 'AssetChartsView',
    components: {
        ApexCharts,
        DateRangeInput,
        Spinner,
    },
    mixins: [ChartHelper],
    props: {
        id: {
            type: [String, Number],
            required: true,
        },
    },
    data() {
        return {
            displayAnnotationLabelsChecked: false,
            series: [
                {
                    data: [],
                },
            ],
            chartOptions: {
                chart: {
                    type: 'area',
                    stacked: false,
                    zoom: {
                        type: 'x',
                        enabled: true,
                        autoScaleYaxis: true,
                    },
                    toolbar: {
                        autoSelected: 'zoom',
                    },
                },
                annotations: {
                    xaxis: [],
                },
                plotOptions: {
                    line: {
                        curve: 'smooth',
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                markers: {
                    size: 0,
                    style: 'full',
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shadeIntensity: 1,
                        inverseColors: false,
                        opacityFrom: 0.7,
                        opacityTo: 0,
                    },
                },
                yaxis: {
                    title: {
                        text: this.$i18n.t('fillLevel'),
                    },
                    min: 0,
                    max: 100,
                },
                xaxis: {
                    type: 'datetime',
                },
                tooltip: {
                    shared: false,
                    x: {
                        format: 'dd.MM.yy HH:mm:ss',
                    },
                },
            },
            customRange: {
                startDate: moment()
                    .startOf('day')
                    .toDate(),
                endDate: moment()
                    .endOf('day')
                    .toDate(),
            },
            isLoading: false,
        }
    },
    computed: {
        converter() {
            return this.$route.name === 'sbbbinchart'
                ? measurementHelper.convertToSbbBinLevel
                : value => parseInt(value * 100)
        },
        fillLevelSensorDataKey() {
            return this.$route.name === 'sbbbinchart'
                ? 'distance'
                : 'fill_level'
        },
    },
    methods: {
        updateYAxis() {
            this.chartOptions.yaxis.min = this.getYMinValue()
            this.chartOptions.yaxis.max = this.getYMaxValue()
        },
        loadHistoryCustom(dates) {
            this.loadHistoryFromDateRange({
                startDate: moment(dates.startDate),
                endDate: moment(dates.endDate),
            })
        },
        async loadHistoryFromDateRange(dates) {
            this.isLoading = true
            this.startDate = dates.startDate
            this.endDate = dates.endDate
            this.customRange = {
                startDate: this.startDate.toDate(),
                endDate: this.endDate.toDate(),
            }
            const results = await this.loadData()
            this.addHistoryDataFromResponse(results)
            this.isLoading = false
        },
        async loadData() {
            let results = []
            let historyUrl = [
                'measurements/?',
                'timestamp_min=' + encodeURIComponent(this.startDate.format()),
                '&timestamp_max=' + encodeURIComponent(this.endDate.format()),
                '&tracker=' + this.id,
                '&fields=timestamp,sensor_data',
                '&limit=' + process.env.VUE_APP_LIMIT_RECORDS_PER_REQUEST,
            ].join('')

            while (historyUrl) {
                const { data } = await httpHelper.get(historyUrl)
                historyUrl = data.next || null
                results = [...results, ...data.results]
            }

            return results
        },
        addHistoryDataFromResponse(results) {
            const fillLevelData = results
                .filter(item =>
                    Object.prototype.hasOwnProperty.call(
                        item.sensor_data,
                        this.fillLevelSensorDataKey
                    )
                )
                .map(item => [
                    item.timestamp,
                    this.converter(
                        item.sensor_data[this.fillLevelSensorDataKey]
                    ),
                ])

            this.series = [
                {
                    name: this.$t('fillLevel'),
                    data: fillLevelData,
                },
            ]

            const accelData = results.filter(item =>
                Object.prototype.hasOwnProperty.call(
                    item.sensor_data,
                    'accel_y'
                )
            )

            this.chartOptions.annotations.xaxis = accelData.map(
                (item, index) => {
                    return {
                        x: new Date(item.timestamp).getTime(),
                        strokeDashArray: 0,
                        borderColor: '#fdae61',
                        label: {
                            borderColor: '#fdae61',
                            position: 'top',
                            orientation: 'horizontal',
                            // distribute labels across y-axis to reduce overlapping.
                            // The first label (index 0) should start at offset 25 to avoid overlapping the toolbar.
                            // There is space for 13 labels,
                            // therefore use the modulo operator to not fall outside the canvas.
                            offsetY: 25 + ((index * 25) % (13 * 25)),
                            style: {
                                color: '#fff',
                                background: '#fdae61',
                                fontSize: '14px',
                            },
                            text:
                                this.$i18n.t('collection') +
                                ': ' +
                                moment(item.timestamp).format(
                                    'DD.MM.YYYY HH:mm'
                                ),
                        },
                    }
                }
            )
        },
    },
}
</script>

<i18n>
{
    "en": {
        "fillLevel": "Fill level (%)",
        "collection": "Collection",
        "displayAnnotationLabels": "Display collection dates"
    },
    "de": {
        "fillLevel": "Füllstand (%)",
        "collection": "Leerung",
        "displayAnnotationLabels": "Daten der Leerungen anzeigen"
    },
    "it": {
        "fillLevel": "Riempimento (%)",
        "collection": "Svuotamento",
        "displayAnnotationLabels": "Visualizzazione dei dati dei vuoti"
    }
}
</i18n>

<style lang="scss">
.chart {
    .apexcharts-xaxis-annotations rect,
    .apexcharts-xaxis-annotations text {
        visibility: hidden;
    }

    .chart-display-annotation-labels .apexcharts-xaxis-annotations rect,
    .chart-display-annotation-labels .apexcharts-xaxis-annotations text {
        visibility: visible;
    }
}
</style>

<style lang="scss" scoped>
.chart-holder {
    display: flex;
    @include respond-to('for-tablet-down') {
        display: block;
    }
}

.chart {
    flex-grow: 100;
    margin: 1rem 1rem 1rem 0;
    border-left: $style-border;
    @include respond-to('for-tablet-down') {
        border: none;
    }
}

input[type='checkbox'] {
    margin-left: 50px;
}

label {
    padding-left: 10px;
}

.datepicker {
    width: 20%;
    min-width: 350px;

    @include respond-to('for-tablet-down') {
        padding: 0 0 1rem;
        margin: 1rem 1rem 0;
        border-bottom: $style-border;
    }
}
</style>
